<template>
  <div>

    <agent-list-add-new
      :is-add-new-agent-sidebar-active.sync="isAddNewAgentSidebarActive"
      :form-title="formTitle"
      :agent-name="agentName"
      :new-agent="newAgent"
      :agents-data="agentsData"
      @refetch-data="fetchAgents"
    />

    <b-button
      class="mb-2"
      variant="primary"
      @click="showAddForm('Add New Agent', '')"
    >
      <span class="text-nowrap">Add New Agent</span>
    </b-button>

    <div
      class="d-flex pb-1 flex-wrap"
    >
      <div
        v-for="letter in alphabet"
        :key="letter"
        class="flex-fill"
      >
        <b-link
          :href="'#' + letter"
          :disabled="!lettersFound.includes(letter)"
          class="btn btn-link p-0"
        >{{ letter }}</b-link>
      </div>
    </div>

    <div
      v-for="(agent, index) in agentsData"
      :key="'header' + index"
    >
      <h1 :id="agent.anchor">
        {{ agent.name }}
      </h1>
      <b-button
        v-if="!(isManager && agent.users.length > 0)"
        :key="'button' + index"
        variant="primary"
        size="sm"
        @click="showAddForm(isPlatform ? 'Add User' : 'Invite User', agent.name)"
      >
        <span class="text-nowrap">{{ isPlatform ? 'Add User' : 'Invite User' }}</span>
      </b-button>
      <b-table
        :key="'table' + index"
        :fields="tableColumns(agent.users)"
        :items="agent.users"
        fixed
      >
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.photo"
                :text="avatarText(data.item.full_name)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="isPlatform ? { name: 'apps-users-view', params: { id: data.item.id } }: null"
              />
            </template>
            <b-link
              v-if="isPlatform"
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              <div class="p-1">
                {{ data.item.full_name }}{{ ' ' }}
                <template
                  v-if="data.item.job_description"
                >({{ data.item.job_description }})</template>
              </div>
            </b-link>
            <div
              v-else
              class="p-1"
            >
              {{ data.item.full_name }}{{ ' ' }}
              <template
                v-if="data.item.job_description"
              >({{ data.item.job_description }})</template>
            </div>
            <b-badge
              v-if="!data.item.is_active"
              variant="danger"
              class="ml-1"
            >
              Inactive
            </b-badge>
          </b-media>
        </template>
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ resolveUserRoleText(data.item.role) }}</span>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="isManager || isPlatform"
              @click="sendInvite(data.item.email)"
            >
              <feather-icon icon="SendIcon" />
              <span class="align-middle ml-50">Invite</span>
            </b-dropdown-item>
            <template
              v-if="isPlatform"
            >
              <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.role === 'agent'"
                @click="enableManager(data.item.email)"
              >
                <feather-icon icon="UserPlusIcon" />
                <span class="align-middle ml-50">Enable Manager permission</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.role === 'agent-manager'"
                @click="disableManager(data.item.email)"
              >
                <feather-icon icon="UserMinusIcon" />
                <span class="align-middle ml-50">Disable Manager permission</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.is_active"
                @click="disableUser(data.item.email)"
              >
                <feather-icon icon="UserXIcon" />
                <span class="align-middle ml-50">Disable User</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="!data.item.is_active"
                @click="enableUser(data.item.email)"
              >
                <feather-icon icon="UserCheckIcon" />
                <span class="align-middle ml-50">Enable User</span>
              </b-dropdown-item>
            </template>
          </b-dropdown>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import axios from '@axios'
import {
  BAvatar, BButton, BBadge, BDropdown, BDropdownItem, BLink, BMedia, BTable,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'
import AgentListAddNew from '@/views/pages/agents/AgentListAddNew.vue'

/* eslint-disable global-require */
export default {
  components: {
    BAvatar,
    BBadge,
    BButton,
    BDropdown,
    BDropdownItem,
    BLink,
    BMedia,
    BTable,
    AgentListAddNew,
  },
  data() {
    const userRole = JSON.parse(localStorage.getItem('userData')).role
    const isManager = userRole === 'manufacturer-manager' || userRole === 'brand-manager' || userRole === 'agent-manager'
    const isPlatform = userRole === 'platform-manager'
    return {
      agentsData: { },
      userRole,
      isManager,
      isPlatform,
      isAddNewAgentSidebarActive: false,
      formTitle: 'Add New Agent',
      agentName: '',
      newAgent: true,
      alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L',
        'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
      lettersFound: [],
    }
  },
  created() {
    this.fetchAgents()
  },
  methods: {
    avatarText,
    resolveUserRoleVariant(role) {
      return role === 'agent-manager' ? 'danger' : 'primary'
    },
    resolveUserRoleIcon(role) {
      return role === 'agent-manager' ? 'SettingsIcon' : 'UserIcon'
    },
    resolveUserRoleText(role) {
      if (role === 'agent-manager') return 'Agent Manager'
      if (role === 'agent') return 'Agent'
      return role
    },
    tableColumns(users) {
      if (this.isPlatform) {
        return ['user', 'email', 'date_joined', 'role', 'actions']
      }
      if (this.isManager) {
        const loggedUsers = users.filter(user => user.last_login)
        return loggedUsers.length === 0 ? [
          'user', 'email', 'date_joined', 'role', 'actions'] : [
          'user', 'email', 'date_joined', 'role']
      }
      return ['user', 'email', 'date_joined', 'role']
    },
    sendInvite(email) {
      axios.post('/api/v1/users/reset_password/', { email, invite: true })
        .then(() => this.$toast({
          component: ToastificationContent,
          props: {
            title: `Invite sent to ${email}`,
            icon: 'SentIcon',
            variant: 'success',
          },
        }))
    },
    fetchAgents() {
      this.$http.get('/api/v1/agents/')
        .then(response => {
          let letterFound = null
          this.agentsData = response.data.map(agent => {
            let { users } = agent
            users = users.map(user => ({
              ...user,
              role: user.groups.map(group => group.name).join(', '),
            }))
            const anchor = agent.name[0].toUpperCase()
            if (anchor !== letterFound) {
              letterFound = anchor
              this.lettersFound.push(anchor)
              return { ...agent, anchor, users }
            }
            return { ...agent, users }
          })
        })
    },
    showAddForm(formTitle, name) {
      this.formTitle = formTitle
      this.newAgent = name === ''
      this.agentName = name
      this.isAddNewAgentSidebarActive = true
    },
    enableManager(email) {
      axios.post('/api/v1/users/enable_manager/', { email })
        .then(() => this.$toast({
          component: ToastificationContent,
          props: {
            title: `Add manager permission to ${email}`,
            icon: 'UserPlusIcon',
            variant: 'success',
          },
        })).then(this.fetchAgents)
    },
    disableManager(email) {
      axios.post('/api/v1/users/disable_manager/', { email })
        .then(() => this.$toast({
          component: ToastificationContent,
          props: {
            title: `Disable manager permission to ${email}`,
            icon: 'UserMinusIcon',
            variant: 'success',
          },
        })).then(this.fetchAgents)
    },
    enableUser(email) {
      axios.post('/api/v1/users/enable_user/', { email })
        .then(() => this.$toast({
          component: ToastificationContent,
          props: {
            title: `User ${email} enabled`,
            icon: 'UserCheckIcon',
            variant: 'success',
          },
        })).then(this.fetchAgents)
    },
    disableUser(email) {
      axios.post('/api/v1/users/disable_user/', { email })
        .then(() => this.$toast({
          component: ToastificationContent,
          props: {
            title: `User ${email} disabled`,
            icon: 'UserXIcon',
            variant: 'success',
          },
        })).then(this.fetchAgents)
    },
  },
}
/* eslint-disable global-require */
</script>
<style lang="scss">
html {
    scroll-padding-top: 90px;
    scroll-behavior: smooth;
}
</style>
